import React from "react";
import {CodeBlend} from "../components/code";

const options = [
    {
        name: 'ODBC.LIBS',
        scope: 'api',
        description: `Configures SQLAPI++ Library to use specified ODBC manager library.`,
        valid:
            <>
                Any valid ODBC manager library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows - {' '}<code>"odbc32.dll"</code></li>
                <li>Linux - {' '}<code>"libiodbc.so.:libiodbc.so.3:libiodbc.so.2:libodbc.so:libodbc.so.1"</code></li>
            </ul>,
        special:
            <>
                <code>"STATIC"</code>, forces using the linked ODBC API functions when
                the library is compiled with {' '}<code className="text-reset">SA_STATIC_ODBC</code>{' '} build option.
            </>
    },
    {
        name: 'SQL_ATTR_CONNECTION_POOLING',
        scope: 'api',
        description:
            <>
                Configures SQLAPI++ to set ODBC's {' '}<CodeBlend>SQL_ATTR_CONNECTION_POOLING</CodeBlend>{' '} attribute
                just after the ODBC API is initialized. See ODBC documentation for more information on this attribute.
            </>,
        valid:
            <ul>
                <li><code>"SQL_CP_OFF"</code></li>
                <li><code>"SQL_CP_ONE_PER_DRIVER"</code></li>
                <li><code>"SQL_CP_ONE_PER_HENV"</code></li>
            </ul>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'SQL_ATTR_CP_MATCH',
        scope: 'api',
        description:
            <>
                Configures SQLAPI++ to set ODBC's {' '}<CodeBlend>SQL_ATTR_CP_MATCH</CodeBlend>{' '} attribute just
                after the ODBC API is initialized. See ODBC documentation for more information on this attribute.
            </>,
        valid:
            <ul>
                <li><code>"SQL_CP_STRICT_MATCH"</code></li>
                <li><code>"SQL_CP_RELAXED_MATCH"</code></li>
            </ul>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'ODBCUseNumeric',
        scope: 'connection',
        description:
            <>
                Configures SQLAPI++ library to use {' '}<CodeBlend>SQL_NUMERIC_STRUCT</CodeBlend>{' '} program type for
                {' '}<CodeBlend>SQL_NUMERIC</CodeBlend>,
                {' '}<CodeBlend>SQL_DECIMAL</CodeBlend>{' '} and
                {' '}<CodeBlend>SQL_BIGINT</CodeBlend>{' '} database types. By default SQLAPI++ uses character
                buffer to prevent any problems with ODBC drivers those don't
                support {' '}<CodeBlend>SQL_NUMERIC_STRUCT</CodeBlend>.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'ODBCUseBigint',
        scope: 'connection',
        description:
            <>
                Configures SQLAPI++ library to use 64-bit integer program type for
                {' '}<CodeBlend>SQL_C_SBIGINT</CodeBlend>{' '} and
                {' '}<CodeBlend>SQL_C_UBIGINT</CodeBlend>{' '} database types. By default SQLAPI++ uses character buffer
                to prevent any problems with ODBC drivers those don't support 64-bit integer program types.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'SQL_DRIVER_PROMPT, SQL_DRIVER_COMPLETE, SQL_DRIVER_COMPLETE_REQUIRED',
        scope: 'connection',
        description:
            <>
                SQLAPI++ uses {' '}<CodeBlend>SQLConnect</CodeBlend>{' '} (if connection string doesn't include '='
                symbol) or {' '}<CodeBlend>SQLDriverConnect</CodeBlend>{' '} ODBC's function to initiate database
                connection. When one of these parameters is specified {' '}<CodeBlend>SQLDriverConnect</CodeBlend>{' '}
                is used with appropriate driver completion flag (see ODBC documentation for more information).
            </>,
        valid:
            <>
                String that represent a parent window handle in a hexadecimal form
            </>,
        defaultValue:
            <>
                none. By default SQLAPI++ uses {' '}<CodeBlend>SQL_DRIVER_NOPROMPT</CodeBlend>{' '} driver completion
                flag.
            </>
    },
    {
        name: 'DSN',
        scope: 'connection',
        description:
            <>
                Readonly. This option value is set to the full connection string by ODBC Driver Manager.
                Requires {' '}<CodeBlend>SQL_DRIVER_PROMPT</CodeBlend>,
                {' '}<CodeBlend>SQL_DRIVER_COMPLETE</CodeBlend>{' '} or
                {' '}<CodeBlend>SQL_DRIVER_COMPLETE_REQUIRED</CodeBlend>{' '} option to be set.
            </>
    },
    {
        name: 'SQL_ATTR_CONNECTION_TIMEOUT',
        scope: 'connection',
        description:
            <>
                Configures SQLAPI++ to set ODBC's {' '}<CodeBlend>SQL_ATTR_CONNECTION_TIMEOUT</CodeBlend>{' '} attribute
                just before the first connection is made. See ODBC documentation for more information on this attribute.
            </>,
        valid:
            <>
                String that represents {' '}<CodeBlend>SQLUINTEGER</CodeBlend>{' '} value corresponding to the number
                of seconds to wait for any request on the connection to complete before returning to the application
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'ODBCUseSQLGetData',
        scope: 'command',
        description:
            <>
                Configures SQLAPI++ library to use
                {' '}<CodeBlend>SQLGetData</CodeBlend>{' '} call to get the value of any result set field.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'ODBCAddLongTextBufferSpace',
        scope: 'command',
        description:
            <>
                <p>
                    As described in ODBC documentation, for text data, an extra space for
                    {' '}<CodeBlend>'\0'</CodeBlend>{' '} should be allocated in
                    the destination buffer for {' '}<CodeBlend>SQLGetData</CodeBlend>{' '} call. SQLAPI++ does that by
                    default.
                </p>
                <p>
                    But some drivers ignore the fact they have to put {' '}<CodeBlend>'\0'</CodeBlend>{' '} into the
                    buffer. As the result the first symbol of the field can be lost. For such drivers this option should
                    be set to {' '}<code>"False"</code>.
                </p>
            </>,
        valid:
            <>
                <code>"False"</code>, {' '}<code>"0"</code>{' '} - to not allocate extra space in the buffer
            </>,
        defaultValue:
            <code>"True"</code>
    },
    {
        name: 'ODBCDisableLongLengthQuery',
        scope: 'command',
        description:
            <>
                Configures SQLAPI++ library to not query the {' '}<CodeBlend>LONG</CodeBlend>{' '} field length. Some
                buggy ODBC drivers can truncate the field content with default processing.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'ODBCOmitSQLFreeStmt',
        scope: 'command',
        description:
            <>
                Configures SQLAPI++ library to not execute the {' '}<CodeBlend>SQLFreeStmt</CodeBlend>{' '} function. Some
                buggy ODBC drivers can close related SQLHSTMT handle.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'PreFetchRows',
        scope: 'command',
        description: `Forces SQLAPI++ library to fetch rows in bulk, rather than retrieving records one by one.`,
        valid: 'String containing number of rows in the fetch buffer',
        defaultValue: <code>"1"</code>
    },
    {
        name: 'UseDynamicCursor, Scrollable',
        scope: 'command',
        description:
            <>
                Forces SQLAPI++ to use scrollable dynamic command handle. Sets the command handle attributes
                {' '}<CodeBlend>SQL_ATTR_CURSOR_TYPE</CodeBlend>{' '} = {' '}<CodeBlend>SQL_CURSOR_DYNAMIC</CodeBlend>{' '}
                and {' '}<CodeBlend>SQL_ATTR_CONCURRENCY</CodeBlend> = {' '}<CodeBlend>SQL_CONCUR_LOCK</CodeBlend>.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'SetCursorName',
        scope: 'command',
        description: `Allows to define the cursor name SQLAPI++ uses for the server side cursor.`,
        valid:
            <>
                See {' '}<CodeBlend>SQLSetCursorName</CodeBlend>{' '} documentation.
            </>,
        defaultValue:
            <>
                none
            </>
    },
    {
        name: 'ExecDirect',
        scope: 'command',
        description:
            <>
                Forces SQLAPI++ to use {' '}<CodeBlend>SQLExecDirect</CodeBlend>{' '} instead of
                {' '}<CodeBlend>SQLExecute</CodeBlend>{' '} API function.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue: <code>"False"</code>
    },
    {
        name: 'SQL_ATTR_CONCURRENCY',
        scope: 'command',
        description: `Sets the statement concurrency attribute. See ODBC documentation for additional
                      information.`,
        valid:
            <>
                <code>"SQL_CONCUR_READONLY"</code>,
                {' '}<code>"SQL_CONCUR_VALUES"</code>,
                {' '}<code>"SQL_CONCUR_ROWVER"</code>,
                {' '}<code>"SQL_CONCUR_LOCK"</code>
            </>
    },
    {
        name: 'SQL_ATTR_CURSOR_TYPE',
        scope: "command",
        description: `Sets the statement cursor type. See ODBC documentation for additional information.`,
        valid:
            <>
                <code>"SQL_CURSOR_FORWARD_ONLY"</code>,
                {' '}<code>"SQL_CURSOR_KEYSET_DRIVEN"</code>,
                {' '}<code>"SQL_CURSOR_DYNAMIC"</code>,
                {' '}<code>"SQL_CURSOR_STATIC"</code>
            </>
    },
    {
        name: 'SQL_ATTR_CURSOR_SCROLLABLE',
        scope: 'command',
        description: `Configures the statement cursor to be scrollable. See ODBC documentation for additional information.`,
        valid:
            <>
                <code>"SQL_NONSCROLLABLE"</code>,
                {' '}<code>"SQL_SCROLLABLE"</code>
            </>
    },
    {
        name: 'SQL_ATTR_CURSOR_SENSITIVITY',
        scope: 'command',
        description: `Sets the statement cursor sensitivity. See ODBC documentation for additional information.`,
        valid:
            <>
                <code>"SQL_UNSPECIFIED"</code>,
                {' '}<code>"SQL_INSENSITIVE"</code>,
                {' '}<code>"SQL_SENSITIVE"</code>
            </>
    },
    {
        name: 'SQL_ATTR_QUERY_TIMEOUT',
        scope: 'command',
        description: `Sets the integer value corresponding to the number of seconds to wait for an SQL statement
                      to execute before returning to the application.`,
        valid:
            <>
                String that represents an integer value in seconds
            </>,
        defaultValue:
            <>none</>
    }
];

export {
    options
};
